import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { authGuard } from './auth.guard';
import { DocumentsComponent } from './documents/documents.component';
import { LoginComponent } from './login/login.component';
import { ControlRoutes } from './routes';

const routes: Routes = [
  { path: '', component: LoginComponent },
  // { path: '', redirectTo: 'login' },
  { path: ControlRoutes.Login, component: LoginComponent },
  // { path: 'documents', component: DocumentsComponent },
  // { path: 'documents/**', component: DocumentsComponent },
  {
    path: '**',
    component: DocumentsComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    //force same route to re init every time
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
