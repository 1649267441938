import { Component } from '@angular/core';
import { DocumentsService } from '../documents.service';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  faUser = faUser;

  constructor(
    private docsService: DocumentsService,
    private authService: AuthService
  ) {}

  UserName() {
    return this.authService.userName;
  }

  onLogout() {
    console.log('navbar logout');
    this.authService.logout();
  }
}
