import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ControlRoutes } from 'src/app/routes';

import { AuthService } from './auth.service';

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isLoggedIn) {
    return true;
  }

  router.navigateByUrl(ControlRoutes.Login);
  return false;
};
