<meta name="theme-color" content="#563d7c" />

<style>
  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
</style>
<link href="/assets/floating-labels.css" rel="stylesheet" />

<div class="mt-50">
  <!-- TODO Fix -->

  <form
    #form="ngForm"
    class="form-signin"
    (ngSubmit)="onSubmit(form)"
    novalidate
  >
    <div class="text-center mb-4" [@fade]>
      <img class="mb-4" src="/assets/Logo.png" alt="" width="72" height="72" />
      <h1 class="h3 mb-3 font-weight-normal">Zenith Documents</h1>
    </div>

    <div *ngIf="errorMessage" class="alert alert-warning">
      <fa-icon [icon]="faExclamationTriangle"></fa-icon>&nbsp;{{ errorMessage }}
    </div>
    <div *ngIf="timeoutMessage" class="alert alert-info">
      <fa-icon [icon]="faInfoCircle"></fa-icon>&nbsp;{{ timeoutMessage }}
    </div>

    <div class="form-label-group" [@fadeSlow]>
      <input
        type="email"
        id="userName"
        name="userName"
        class="form-control"
        placeholder="Username"
        required
        autofocus
        [(ngModel)]="ac.userName"
        #username="ngModel"
      />
      <label for="userName">Username</label>
      <!-- <div class="text-danger" *ngIf="username.touched && username.invalid && username.errors.required">Username required</div> -->
    </div>

    <div class="form-label-group" [@fadeSlow]>
      <input
        type="password"
        id="password"
        name="password"
        class="form-control"
        placeholder="Password"
        required
        [(ngModel)]="ac.password"
        #password="ngModel"
      />
      <label for="password">Password</label>
      <!-- <div class="text-danger" *ngIf="password.touched && password.invalid && password.errors.required">Password required</div> -->
    </div>

    <div class="checkbox mb-3" [@fadeSlow]>
      <label>
        <input
          type="checkbox"
          [(ngModel)]="rememberMe"
          [ngModelOptions]="{ standalone: true }"
        />
        Remember me
      </label>
    </div>

    <ng-template [ngIf]="submitting">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Authenticating...</span>
      </div>
    </ng-template>

    <ng-template [ngIf]="!submitting">
      <button
        class="btn btn-lg btn-primary btn-block w-100"
        type="submit"
        [@fadeSlow]
        *ngIf="this.submitVisible"
        [disabled]="form.invalid || submitting"
      >
        Sign in
      </button>
    </ng-template>

    <p class="mt-5 mb-3 text-muted text-center" [@fadeSlow]>
      &copy; {{ todayDate | date : "yyyy" }} Zenith Information Systems, Inc.
    </p>
  </form>
</div>
