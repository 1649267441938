import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private http: HttpClient) {}

  private _requestBase = environment.zisApiBaseUrl;

  private cleanPath(path: string): string {
    return path.startsWith('/documents/') ? path.slice(11) : path.slice(10);
  }

  getExtensionAllowed(): Observable<any> {
    return this.http.get<any>(
      `${this._requestBase}zdocuments/extension-allowed`
    );
  }

  getAllDocuments(apiDocsPath: string): Observable<any> {
    return this.http.get<any>(
      `${this._requestBase}zdocuments`,
      this.buildHTTPOptions({ params: { path: this.cleanPath(apiDocsPath) } })
    );
  }

  getFileUrl(apiDocsPath: string): Observable<any> {
    return this.http.get<any>(
      `${this._requestBase}zdocuments/file`,
      this.buildHTTPOptions({
        params: { filename: this.cleanPath(apiDocsPath) },
      })
    );
  }

  deleteFile(apiDocsPath: string): Observable<any> {
    return this.http.delete<any>(
      `${this._requestBase}zdocuments/file`,
      this.buildHTTPOptions({
        params: { path: this.cleanPath(apiDocsPath) },
      })
    );
  }

  uploadFile(file: FormData, apiDocsPath: string): Observable<any> {
    const req = new HttpRequest(
      'POST',
      `${this._requestBase}zdocuments/uploadFile?path=${this.cleanPath(
        apiDocsPath
      )}`,
      file,
      this.buildHTTPOptions({
        reportProgress: true,
      })
    );

    return this.http.request(req).pipe(
      map((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = event.total
            ? Math.round((100 * event.loaded) / event.total)
            : 0;
          return percentDone;
        }
        return;
      })
    );
  }

  createFolder(folderName: string, apiDocsPath: string): Observable<any> {
    return this.http.post<any>(`${this._requestBase}zdocuments`, {
      folderName: folderName,
      folderPath: this.cleanPath(apiDocsPath),
    });
  }

  deleteFolder(apiDocsPath: string): Observable<any> {
    return this.http.delete<any>(
      `${this._requestBase}zdocuments`,
      this.buildHTTPOptions({
        params: { path: this.cleanPath(apiDocsPath) },
      })
    );
  }

  buildHTTPOptions(aditionalOptions?: any) {
    const httpOptions = {
      ...aditionalOptions,
    };

    return httpOptions;
  }
}
