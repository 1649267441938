import { LogLevel } from "src/app/log.service";

export class LogEntry {

  entryDate: Date = new Date();
  level: LogLevel = LogLevel.Debug;
  message: string = "";
  logData: any[] = [];

}
