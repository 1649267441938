import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-create-folder',
  templateUrl: './modal-create-folder.component.html',
  styleUrls: ['./modal-create-folder.component.scss'],
})
export class ModalCreateFolderComponent {
  control: FormControl;
  @Output() createEvent = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {
    this.control = this.fb.control(
      { value: '', disabled: false },
      { validators: Validators.required }
    );
  }

  createFolder(): void {
    this.createEvent.emit(this.control);
  }
}
