import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dropdown-actions',
  templateUrl: './dropdown-actions.component.html',
  styleUrls: ['./dropdown-actions.component.scss'],
})
export class DropdownItemActionsComponent {
  @Input() isIcon: boolean = false;
  @Input() actions: any[] = [];

  @Output() onActionClick = new EventEmitter<string>();

  faEllipsisVertical = faEllipsisVertical;

  actionClick(actionId: string) {
    this.onActionClick.emit(actionId);
  }
}
