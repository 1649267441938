<div ngbDropdown class="d-inline-block">
  <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
    <ng-template [ngIf]="isIcon">
      <fa-icon [icon]="faEllipsisVertical"></fa-icon>
    </ng-template>

    <ng-template [ngIf]="!isIcon"> Actions </ng-template>
  </button>

  <div ngbDropdownMenu>
    <button
      ngbDropdownItem
      *ngFor="let item of actions"
      (click)="actionClick(item.actionId)"
    >
      {{ item.label }}
    </button>
  </div>
</div>
