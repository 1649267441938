<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Upload file</h4>
</div>
<div class="modal-body">
  <div class="mb-3 dropzone" appFileDnd (fileDropped)="onFileDropped($event)">
    <ng-container *ngIf="control.invalid">
      <input
        class="form-control"
        type="file"
        #fileRef
        (change)="onFileSelected($event)"
        [accept]="acceptedExtensions()"
      />
      <fa-icon [icon]="faCloudArrowUp" size="3x"></fa-icon>
      <h3 class="mt-3">Drag and drop a file</h3>
      <h3>or</h3>
      <button type="button" class="btn btn-primary" (click)="fileRef.click()">
        Browse for file
      </button>
    </ng-container>

    <ng-container *ngIf="control.valid">
      <fa-icon [icon]="faCloudArrowUp" size="3x"></fa-icon>
      <h3 class="mt-3">{{ control.value.name }}</h3>
      <p *ngIf="loading">
        <ngb-progressbar
          type="primary"
          [value]="progress"
          [showValue]="true"
        ></ngb-progressbar>
      </p>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-black"
    (click)="activeModal.dismiss()"
    [disabled]="loading"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="uploadFile()"
    [disabled]="control.invalid || loading"
  >
    <ng-container *ngIf="!loading"> Upload </ng-container>
    <ng-container *ngIf="loading">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span>Uploading...</span>
    </ng-container>
  </button>
</div>
