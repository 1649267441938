<ngb-toast
  class="bg-primary text-bg-primary mb-1 mw-100"
  *ngFor="let toast of toastService.toasts"
  [header]="toast.header"
  [autohide]="true"
  (hiddden)="toastService.remove(toast)"
  [delay]="toast.delay || 5000"
>
  {{ toast.body }}
</ngb-toast>
