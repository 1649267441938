<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Create folder</h4>
</div>
<div class="modal-body">
  <form>
    <div class="mb-3">
      <label for="dateOfBirth">Folder name</label>
      <div class="input-group">
        <input class="form-control" type="text" [formControl]="control" />
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-black"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="createFolder()"
    [disabled]="control.invalid"
  >
    Save
  </button>
</div>
