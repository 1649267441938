<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <p>{{ body }}</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-black"
    (click)="activeModal.dismiss()"
  >
    No
  </button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="activeModal.close()"
  >
    Yes
  </button>
</div>
